import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react";
import { API_URL } from "./constants";
import LoadingIcon from "./components/LoadingIcon";

function App() {
  const [dream, setDream] = useState("");
  const [user_id, setuserId] = useState("");
  const [histories, setHistories] = useState([]);
  const [loading, setLoading] = useState(false);

  // Get histories of dream for specific user from API
  const fetchHistory = (userId) => {
    axios
      .get(`${API_URL}dreams?user_id=${userId}`)
      .then((res) => {
        setHistories(res.data);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  // Main API to translate the dream
  // It calls the API to get the dream's interpretation
  const handleTranslate = () => {
    if (dream?.length) {
      setLoading(true);
      axios
        .post(`${API_URL}interpret`, { dream, user_id })
        .then((res) => {
          setHistories([
            {
              date: res.data.date,
              dream: res.data.dream,
              interpretation: res.data.interpretation,
            },
            ...histories,
          ]);
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    }
  };

  // Checks if there are any existing `user_id` saved on the browser first
  // If Notification, then generate a uuid as user_id and save in localStorage
  useEffect(() => {
    let userId = localStorage.getItem("user_id");
    if (userId) {
      setuserId(userId);
    } else {
      userId = uuidv4();
      localStorage.setItem("user_id", userId);
      setuserId(userId);
    }
    fetchHistory(userId);
  }, []);

  return (
    <div
      className="min-h-screen bg-cover bg-center bg-no-repeat text-white p-8"
      style={{
        background: "url('bg_img.jpg') no-repeat center center fixed",
        backgroundSize: "cover",
      }}
    >
      <div className="max-w-3xl mx-auto">
        <h1 className="text-4xl font-bold text-center mb-4 text-pink-500">
          AI Dream Interpreter
        </h1>
        <p className="text-center mb-8">
          Unlock the secret of your dream. Dreams are a mysterious message
          whispering to us in the subconscious. Explore the depth of
          self-understanding and the will of life through the dream area.
        </p>

        <textarea
          className="w-full p-4 rounded-lg bg-gray-800 text-white border border-pink-500 focus:outline-none focus:ring-2 focus:ring-pink-500 mb-4 opacity-70"
          rows="5"
          placeholder="Enter the contents of your dream."
          value={dream}
          onChange={(e) => setDream(e.target.value)}
        />

        <div className="text-center">
          {loading ? (
            <LoadingIcon />
          ) : (
            <button
              className="bg-pink-500 hover:bg-pink-600 text-white font-bold py-2 px-4 rounded"
              onClick={handleTranslate}
            >
              Translate
            </button>
          )}
        </div>

        {histories &&
          histories.map((history) => (
            <div className="mt-8 bg-gray-800 rounded-lg p-6 border border-pink-500">
              <div className="flex justify-between items-center mb-4">
                <span className="text-gray-400">{history.date}</span>
                <button className="text-gray-400 hover:text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              <p className="font-bold mb-4">{history.dream}</p>
              <p className="text-gray-300">{history.interpretation}</p>
              <button className="mt-4 text-sm bg-gray-700 hover:bg-gray-600 text-white font-bold py-1 px-3 rounded">
                Share
              </button>
            </div>
          ))}
      </div>
    </div>
  );
}

export default App;
